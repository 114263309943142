import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  Box,
  Image,
  Text,
  Input,
  Grid,
  GridItem,
  FormLabel,
  useColorModeValue,
  Textarea,
  Select,
  Spinner,
} from "@chakra-ui/react";
import emailCampaign from "../../../../../assets/img/dashboards/emailcampaign.png";
import emptySturc from "../../../../../assets/img/dashboards/emptylayout.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './AddCampaigns.css'
import TimezoneSelect from "react-timezone-select";
import { useApiContext } from "contexts/apiContext";
import { addCampaigns, getAllGroup } from "libs/apiFunctions";
import TimePicker from "react-time-picker";
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';
import { toast } from "react-toastify";

interface GroupData {
  _id: string; // or number, depending on your data
  name: string;
}
const AddCampaigns = (props: {
  isOpen: any;
  onClose: any;
  onAddCampaign: (campaign: any) => void;
  fetchCampain: () => Promise<void>;
}) => {
  const initialRef = useRef(null);
  const iconColor = useColorModeValue("brand.500", "white");
  const [isCreating, setIsCreating] = useState(false);
  const [campaignName, setCampaignName] = useState("");
  const [newCampaign, setNewCampaign] = useState<any>(null);
  const [summary, setSummary] = useState("");
  const [subject, setSubject] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [showRecipientDropdown, setShowRecipientDropdown] = useState(false);
  const [showSenderDetails, setShowSenderDetails] = useState(false);
  const [showSubjectDetails, setShowSubjectDetails] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [showDatePicker, setShowDatePicker] = useState(false);

  const [groupData, setGroupData] = useState<GroupData[]>([]);
  const [selectedGroup, setSelectedGroup] = useState<GroupData | null>(null);

  const handleCreateNew = () => {
    setIsCreating(true);
  };

  useEffect(() => {
    const fetchGroup = async () => {
      let response = await getAllGroup()
      setGroupData(response?.groups)
    }
    fetchGroup()
  }, [])

  const handleSaveAndProceed = () => {
    const campaign = {
      name: campaignName,
      email: "example@example.com",
      domainName: 123,
      date: new Date().toLocaleDateString(),
    };
    setShowDatePicker(true);

    setNewCampaign(campaign);
    props.onAddCampaign(campaign);
    setIsCreating(false);
  };

  const handleAddAnother = () => {
    setNewCampaign(null);
    setIsCreating(false);
    setCampaignName("");
    setSummary("");
    setDescription("");
    props.onClose();
  };

  const handleToggleRecipientDropdown = () => {
    setShowRecipientDropdown(!showRecipientDropdown);
  };

  const handleToggleSenderDetails = () => {
    setShowSenderDetails(!showSenderDetails);
  };

  const handleToggleSubjectDetails = () => {
    setShowSubjectDetails(!showSubjectDetails);
  };
  const modalSize = newCampaign ? "xl" : "md";
  const modalWidth = newCampaign ? "50vw" : "md";
  const modalHeight = newCampaign ? "82vh" : "auto";

  // Handle selection change
  const handleGroupChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedId = event.target.value;
    const group = groupData?.find(g => g?._id === selectedId);
    setSelectedGroup(group || null);
  };

  let userInfo = JSON.parse(sessionStorage.getItem('userData'))
  const [loading, setLoading] = useState(false)
  const handleRest = () => {
    setCampaignName("")
    setDescription("")
    setSummary("")
    setEmail("")
    setSelectedDate(null)
    setNewCampaign(null)
    setIsCreating(false)
  }

  useEffect(() => {
    setEmail(userInfo?.email)
  }, [email])

  const handleCampaigns = async () => {
    setLoading(true)
    let response = await addCampaigns(campaignName, description, summary, email, selectedDate, selectedGroup?._id)
    if (response) {
      setLoading(false)
      await props.fetchCampain()
      props.onClose()
      toast.success("Campaign added successfully!")
      handleRest()
      setIsCreating(false)
    }
    else {
      setLoading(false)
      props.onClose()
      toast.success("Something went wrong, Please try later")
      handleRest()
      setIsCreating(false)
    }
  }


  return (
    <Modal
      initialFocusRef={initialRef}
      isOpen={props.isOpen}
      onClose={props.onClose}
      isCentered
      size={modalSize}
    >
      <ModalOverlay />
      <ModalContent
        maxWidth={modalWidth}
        height={modalHeight}
        overflowY={"auto"}
      >
        <ModalHeader>
          {newCampaign
            ? `${newCampaign.name}`
            : isCreating
              ? "Create Campaign"
              : "Regular Email"}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          {!isCreating && !newCampaign ? (
            <Box textAlign="center" py="10px">
              <Image
                width={"52"}
                src={emailCampaign}
                alt="No Campaigns"
                mb={4}
                mx="auto"
              />
              <Text color="gray.500" fontSize="18px" fontWeight="500">
                Create a regular email campaign to your target audience
              </Text>
              <Button
                alignItems="center"
                justifyContent="center"
                variant="brand"
                top={5}
                mx={2}
                mt={8}
                color="white"
                lineHeight="100%"
                borderRadius="30px"
                onClick={handleCreateNew}
              >
                Create New
              </Button>
            </Box>
          ) : isCreating ? (
            <>
              <FormControl>
                <FormLabel>Campaign Name</FormLabel>
                <Input
                  ref={initialRef}
                  placeholder="Enter campaign name"
                  value={campaignName}
                  onChange={(e) => setCampaignName(e.target.value)}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Summary</FormLabel>
                <Input
                  placeholder="Enter a brief summary"
                  value={summary}
                  onChange={(e) => setSummary(e.target.value)}
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>Description</FormLabel>
                <Textarea
                  placeholder="Enter a detailed description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </FormControl>
            </>
          ) : newCampaign ? (
            <>
              <Box textAlign="center" py="10px">
                <Grid
                  h="auto"
                  templateRows="repeat(5, auto)"
                  templateColumns="repeat(5, 1fr)"
                >
                  {/* <GridItem
                    py={4}
                    rowSpan={1}
                    colSpan={1}
                    borderBottom="1px solid #dceef7"
                  >

                    <Box>
                      <Text
                        color="gray.500"
                        textAlign={"start"}
                        fontSize="md"
                        fontWeight="600"
                      >
                        SUBJECT
                      </Text>
                    </Box>
                  </GridItem>
                  <GridItem
                    colSpan={4}
                    borderBottom="1px solid #dceef7"
                    py={4}
                  >
                    {!showSubjectDetails ? (
                      <Box display={"flex"} flexDirection={"column"}>
                        <Text
                          color="black"
                          fontSize="md"
                          textAlign={"start"}
                          fontWeight="700"
                        >
                          Give a suitable subject to campaign
                        </Text>
                        <Button
                          my={2}
                          rounded={"4"}
                          width={"fit-content"}
                          variant="brand"
                          float={"left"}
                          onClick={handleToggleSubjectDetails}
                        >
                          Add Subject
                        </Button>
                      </Box>
                    ) : (


                      <>
                        <FormControl>
                          <FormLabel>Subject</FormLabel>
                          <Input
                            placeholder="Enter Subject"
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                          />
                        </FormControl>
                        <>

                          <Box mt={4} display="flex" justifyContent="end">
                            <Button
                              variant="brand"
                              onClick={handleSaveAndProceed}
                              mx={2}
                            >
                              Save
                            </Button>
                            <Button
                              variant="outline"
                              onClick={handleToggleSubjectDetails}
                            >
                              Cancel
                            </Button>
                          </Box>
                        </>
                      </>
                    )}

                  </GridItem> */}
                  <GridItem
                    py={4}
                    rowSpan={1}
                    colSpan={1}
                    borderBottom="1px solid #dceef7"
                  >

                    <Box>
                      <Text
                        color="gray.500"
                        textAlign={"start"}
                        fontSize="md"
                        fontWeight="600"
                      >
                        SENDER
                      </Text>
                    </Box>

                  </GridItem>
                  <GridItem
                    colSpan={4}
                    borderBottom="1px solid #dceef7"
                    py={4}
                  >
                    {!showSenderDetails ? (
                      <Box display={"flex"} flexDirection={"column"}>
                        <Text
                          color="black"
                          fontSize="md"
                          textAlign={"start"}
                          fontWeight="700"
                        >
                          Who is sending this email campaign?
                        </Text>
                        <Button
                          color={"#422AFB"}
                          borderColor={"#422AFB"}
                          my={2}
                          rounded={"4"}
                          width={"fit-content"}
                          variant="outline"
                          float={"left"}
                          onClick={handleToggleSenderDetails}
                        >
                          Add Sender Details
                        </Button>
                      </Box>) : (

                      <>
                        <FormControl>
                          <FormLabel>Sender Email</FormLabel>
                          <Input
                            placeholder="Enter Sender Email"
                            value={email}
                          // onChange={(e) => setEmail(e.target.value)}
                          />
                        </FormControl>
                        <>

                          {/* <Box mt={4} display="flex" justifyContent="end">
                            <Button
                              variant="brand"
                              onClick={handleSaveAndProceed}
                              mx={2}
                            >
                              Verify
                            </Button>
                            <Button
                              variant="outline"
                              onClick={handleToggleSenderDetails}
                            >
                              Cancel
                            </Button>
                          </Box> */}
                        </>
                      </>
                    )}
                  </GridItem>
                  <GridItem
                    py={4}
                    rowSpan={1}
                    colSpan={1}
                    borderBottom="1px solid #dceef7"
                  >
                    <Box>
                      <Text
                        color="gray.500"
                        textAlign={"start"}
                        fontSize="md"
                        fontWeight="600"
                      >
                        RECIPIENT
                      </Text>
                    </Box>
                  </GridItem>
                  <GridItem colSpan={4} borderBottom="1px solid #dceef7" py={4}>
                    {!showRecipientDropdown ? (
                      <Box display={"flex"} flexDirection={"column"}>
                        <Text
                          color="black"
                          fontSize="md"
                          textAlign={"start"}
                          fontWeight="700"
                        >
                          Choose the contact lists or segments you wish to send
                          your email campaign to.
                        </Text>
                        <Button
                          color={"#422AFB"}
                          borderColor={"#422AFB"}
                          my={2}
                          rounded={"4"}
                          width={"fit-content"}
                          variant="outline"
                          float={"left"}
                          onClick={handleToggleRecipientDropdown}
                        >
                          Add Recipients
                        </Button>
                      </Box>
                    ) : (
                      <>
                        <Select placeholder="Select recipients" onChange={handleGroupChange} value={selectedGroup?._id || ''}>
                          {groupData?.map((data: GroupData) => (
                            <option key={data?._id} value={data?._id}>
                              {data?.name}
                            </option>
                          ))}
                        </Select>

                        {/* <Box mt={4} display="flex" justifyContent="end">
                          <Button
                            variant="brand"
                            mx={2}
                            onClick={handleSaveAndProceed}
                          >
                            Save
                          </Button>
                          <Button
                            variant="outline"
                            onClick={handleToggleRecipientDropdown}
                          >
                            Cancel
                          </Button>
                        </Box> */}
                      </>
                    )}
                  </GridItem>
                  <GridItem
                    py={4}
                    rowSpan={1}
                    colSpan={1}
                    borderBottom="1px solid #dceef7"
                  >
                    <Box>
                      <Text
                        color="gray.500"
                        textAlign={"start"}
                        fontSize="md"
                        fontWeight="600"
                      >
                        SCHEDULE TIME
                      </Text>
                    </Box>
                  </GridItem>
                  <GridItem colSpan={4} borderBottom="1px solid #dceef7" py={4}>
                    <Box display={"flex"} flexDirection={"column"}>
                      <Text
                        color="black"
                        fontSize="md"
                        textAlign={"start"}
                        fontWeight="700"
                      >
                        When will the email be sent?
                      </Text>
                      {showDatePicker && (
                        <Box mt={2} display={"flex"} mr={"auto"}>
                          <DatePicker
                            selected={selectedDate}
                            onChange={(date: Date | null) =>
                              setSelectedDate(date)
                            }
                            showTimeSelect
                            dateFormat="Pp"
                            placeholderText="Select Date and Time"
                            className="datecustom"
                          />
                        </Box>
                      )}
                    </Box>
                  </GridItem>
                  <GridItem py={4} rowSpan={1} colSpan={1}>
                    <Box>
                      <Text
                        color="gray.500"
                        textAlign={"start"}
                        fontSize="md"
                        fontWeight="600"
                      >
                        CONTENT
                      </Text>
                    </Box>
                  </GridItem>
                  <GridItem colSpan={4} py={4}>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"center"}
                      mr={20}
                    >
                      <Image
                        filter={"grayscale(1)"}
                        width={"32"}
                        src={emptySturc}
                        alt="No Campaigns"
                        mb={4}
                      />
                      {/* <Text color="black" fontSize="md" fontWeight="700">
                        Create the content of your campaign.
                      </Text>
                      <Button
                        color={"#422AFB"}
                        borderColor={"#422AFB"}
                        top={6}
                        rounded={"4"}
                        width={"fit-content"}
                        variant="outline"
                        onClick={handleSaveAndProceed}
                      >
                        Create Content
                      </Button> */}
                    </Box>
                  </GridItem>




                </Grid>
              </Box>
              <Box
                width={"full"}
                display={"flex"}
                justifyContent={"end"}
                mt={4}
                pb={10}
              >
                <Button
                  color={"#fff"}
                  borderColor={"#422AFB"}
                  top={8}
                  rounded={"4"}
                  width={"fit-content"}
                  variant="brand"
                  float={"right"}
                  onClick={handleCampaigns}
                  disabled={!email || !selectedDate || !selectedGroup}
                >
                  {loading ? <Spinner size='sm' mr='2' /> : 'Save and Proceed'}
                </Button>
              </Box>
            </>
          ) : (
            <Box textAlign="center">
              <Image
                width={"52"}
                src={emptySturc}
                alt="No Campaigns"
                mb={4}
                mx="auto"
              />
              <Text color="gray.500" fontSize="lg" fontWeight="500">
                You don’t have any campaigns yet!
              </Text>
            </Box>
          )}
        </ModalBody>
        {isCreating && !newCampaign ? (
          <ModalFooter>
            <Button disabled={!campaignName || !summary || !description} onClick={handleSaveAndProceed} colorScheme="blue" mr={3}>
              Save and Proceed
            </Button>
            <Button onClick={() => {
              props.onClose();
              setIsCreating(false);
            }}>
              Cancel
            </Button>
          </ModalFooter>
        ) : null}
      </ModalContent>
    </Modal>
  );
};

export default AddCampaigns;

import React, { useState } from 'react';
import { Box, Text, Flex, Button, Icon, useColorModeValue, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, useDisclosure } from '@chakra-ui/react';
import { FaRegTrashAlt, FaEye, FaMailBulk } from 'react-icons/fa';

const CampaignCard = ({ campaign }: { campaign: any }) => {
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const iconColor = useColorModeValue('brand.500', 'white');
    const { isOpen, onOpen, onClose } = useDisclosure();

    const handleView = () => {
        onOpen(); // Open the modal
    };

    const handleDelete = () => {
        console.log('Delete:', campaign);
    };

    const handleSendMail = () => {
        console.log('Send Mail:', campaign);
    };


    return (
        <>
            <Box
                p='20px'
                mb='20px'
                borderRadius='10px'
                background={'white'}
                boxShadow='0 4px 8px rgba(0, 0, 0, 0.1)'
                width={{ base: '100%', md: '45%', lg: '30%' }}
            >
                <Text color={textColor} fontSize='lg' fontWeight='700'>{campaign?.name}</Text>
                <Text color={textColor} fontSize='md'>{campaign?.description}</Text>
                <Text color={textColor} fontSize='md'>{campaign?.senderName}</Text>
                <Text color={textColor} fontSize='sm'>{campaign?.scheduledSendTime?.split('T')[0]}</Text>
                <Flex justifyContent='flex-end' mt='10px'>
                    <Button
                        bg={bgButton}
                        mx={1}
                        onClick={handleView}
                    >
                        <Icon as={FaEye} color={iconColor} w='20px' h='20px' />
                    </Button>
                    <Button
                        bg={bgButton}
                        mx={1}
                        onClick={handleDelete}
                    >
                        <Icon as={FaRegTrashAlt} color={iconColor} w='20px' h='20px' />
                    </Button>
                </Flex>
            </Box>

            {/* Modal for viewing campaign details */}
            <Modal isOpen={isOpen} onClose={onClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{campaign?.name}</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        <Text fontWeight="bold">Description:</Text>
                        <Text>{campaign?.description}</Text>

                        <Text fontWeight="bold" mt="10px">Sender Name:</Text>
                        <Text>{campaign?.senderName}</Text>
                        <Text fontWeight="bold" mt="10px">Group Name:</Text>
                        <Text>{campaign?.GroupDetails[0]?.groupName}</Text>

                        <Text fontWeight="bold" mt="10px">Scheduled Send Time:</Text>
                        <Text>{campaign?.scheduledSendTime?.split('T')[0]}</Text>
                    </ModalBody>
                    <ModalFooter>
                        <Button colorScheme="blue" mr={3} onClick={onClose}>
                            Close
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

export default CampaignCard;

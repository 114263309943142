import React, { useState, useRef, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  useColorModeValue,
  FormErrorMessage,
} from "@chakra-ui/react";
import { addContact, editContact } from "libs/apiFunctions";
import { toast } from "react-toastify";

// Define the RowObj type
type ContactData = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string; // Updated to string
  websiteDomain: string;
  linkdedInUrl: string;
  _id: string;
};

const AddContacts = (props: {
  isOpen: boolean;
  onClose: () => void;
  fetchAllContactData: () => Promise<void>;
  type: string;
  editData: ContactData | null;
}) => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    websiteDomain: "",
    linkdedInUrl: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  const [touchedFields, setTouchedFields] = useState({
    firstName: false,
    lastName: false,
    email: false,
    phone: false,
  });

  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    if (props?.editData) {
      setFormData({
        firstName: props.editData.firstName,
        lastName: props.editData.lastName,
        email: props.editData.email,
        phone: props.editData.phone,
        websiteDomain: props.editData.websiteDomain,
        linkdedInUrl: props.editData.linkdedInUrl,
      });
    }
  }, [props.editData]);

  useEffect(() => {
    if (props?.type === "Add Contact") {
      handleClear();
    }
  }, [props?.type]);

  const handleClear = () => {
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      websiteDomain: "",
      linkdedInUrl: "",
    });
    setErrors({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
    });
    setTouchedFields({
      firstName: false,
      lastName: false,
      email: false,
      phone: false,
    });
    setIsFormValid(false);
  };

  const initialRef = useRef<HTMLInputElement>(null);
  const iconColor = useColorModeValue("brand.500", "white");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, type, value } = e.target;

    let updatedValue = value;

    // Sanitize value based on input type
    if (type === "tel") {
      updatedValue = value.replace(/[^\d+\-\(\)\s]/g, '');
    }
    if (type === "email") {
      updatedValue = value.replace(/[^\w@.\-+]/g, '');
    }

    setFormData((prevState) => ({
      ...prevState,
      [name]: updatedValue,
    }));

    setTouchedFields((prevState) => ({
      ...prevState,
      [name]: true,
    }));

    // Validate fields on change
    validateFields();
  };

  const validateFields = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[\d\+\-\(\)\s]*$/;

    const newErrors = {
      firstName: touchedFields.firstName && !formData.firstName ? "First name is required." : "",
      lastName: touchedFields.lastName && !formData.lastName ? "Last name is required." : "",
      email: touchedFields.email
        ? formData.email
          ? emailRegex.test(formData.email)
            ? ""
            : "Invalid email format."
          : "Email is required."
        : "",
      phone: touchedFields.phone
        ? formData.phone
          ? phoneRegex.test(formData.phone)
            ? ""
            : "Invalid phone number format."
          : ""
        : "",
    };

    setErrors(newErrors);

    // Check if there are any errors
    const formIsValid = !Object.values(newErrors).some((error) => error);

    setIsFormValid(formIsValid);

    return formIsValid;
  };

  const handleSave = async () => {
    if (validateFields()) {
      try {
        await addContact(formData);
        props.onClose(); // Close modal after saving
        toast.success("Contact Added Successfully");
        await props.fetchAllContactData();
        handleClear();
      } catch (error) {
        console.error("Error adding contact:", error);
      }
    }
  };

  const handleEdit = async () => {
    if (validateFields()) {
      try {
        await editContact(formData, props?.editData?._id);
        props.onClose(); // Close modal after saving
        toast.success("Contact Updated Successfully");
        await props.fetchAllContactData();
        handleClear();
      } catch (error) {
        console.error("Error updating contact:", error);
      }
    }
  };

  return (
    <Modal
      initialFocusRef={initialRef}
      isOpen={props.isOpen}
      onClose={props.onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{props.type}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormControl isInvalid={!!errors.firstName}>
            <FormLabel>First Name</FormLabel>
            <Input
              ref={initialRef}
              name="firstName"
              placeholder="First name"
              value={formData.firstName}
              onChange={handleChange}
              onBlur={() => setTouchedFields(prev => ({ ...prev, firstName: true }))}
            />
            <FormErrorMessage>{errors.firstName}</FormErrorMessage>
          </FormControl>
          <FormControl mt={4} isInvalid={!!errors.lastName}>
            <FormLabel>Last Name</FormLabel>
            <Input
              name="lastName"
              placeholder="Last name"
              value={formData.lastName}
              onChange={handleChange}
              onBlur={() => setTouchedFields(prev => ({ ...prev, lastName: true }))}
            />
            <FormErrorMessage>{errors.lastName}</FormErrorMessage>
          </FormControl>
          <FormControl mt={4} isInvalid={!!errors.email}>
            <FormLabel>Email</FormLabel>
            <Input
              name="email"
              placeholder="Email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              onBlur={() => setTouchedFields(prev => ({ ...prev, email: true }))}
            />
            <FormErrorMessage>{errors.email}</FormErrorMessage>
          </FormControl>
          <FormControl mt={4} isInvalid={!!errors.phone}>
            <FormLabel>Phone</FormLabel>
            <Input
              name="phone"
              placeholder="Phone"
              type="tel" // Use type="tel" for phone number inputs
              maxLength={10}
              value={formData.phone}
              onChange={handleChange}
              onBlur={() => setTouchedFields(prev => ({ ...prev, phone: true }))}
            />
            <FormErrorMessage>{errors.phone}</FormErrorMessage>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Domain Name</FormLabel>
            <Input
              name="websiteDomain"
              placeholder="Domain Name"
              value={formData.websiteDomain}
              onChange={handleChange}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>LinkedIn URL</FormLabel>
            <Input
              name="linkdedInUrl"
              placeholder="LinkedIn URL"
              value={formData.linkdedInUrl}
              onChange={handleChange}
            />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            variant='brand'
            colorScheme="blue"
            mr={3}
            px={8}
            onClick={props.type === "Add Contact" ? handleSave : handleEdit}
            disabled={!isFormValid} // Disable the button if the form is invalid
          >
            Save
          </Button>
          <Button color={"primary"} border={"1px"} onClick={props.onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AddContacts;

import React, { useRef } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  FormControl,
  FormLabel,
  Input,
  useColorModeValue,
} from "@chakra-ui/react";

const CreateSendEmail = (props: { isOpen: any; onClose: any }) => {
  const initialRef = useRef();
  const iconColor = useColorModeValue("brand.500", "white");

  return (
    <Modal
      initialFocusRef={initialRef}
      isOpen={props.isOpen}
      onClose={props.onClose}
      isCentered
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Send Email</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormControl>
            <FormLabel>Website Domain</FormLabel>
            <Input ref={initialRef} placeholder="Website Domain" />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Contact Name</FormLabel>
            <Input placeholder="Contact Name" />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Contact Email</FormLabel>
            <Input placeholder="Contact Email" />
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button variant='brand' colorScheme="blue" mr={3} px={8}>
            Send
          </Button>
          <Button color={"primary"} border={"1px"} onClick={props.onClose}>
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default CreateSendEmail;

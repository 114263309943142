import React from "react";

// Chakra imports
import { Box } from "@chakra-ui/react";
import SendEmailTable from "./components/SendEmailTable/SendEmailTable";

// Custom components


const sampleData = [
	{
	  name: ["John Doe", true],
	  email: "john@xyz.com",
	  domainName: "www.xyz.com",
	  date: "2023-07-12",
	  info: true
	},
	{
		name: ["John Doe", true],
		email: "john@xyz.com",
		domainName: "www.xyz.com",
		date: "2023-07-12",
		info: true
	  },
	  {
		name: ["John Doe", true],
		email: "john@xyz.com",
		domainName: "www.xyz.com",
		date: "2023-07-12",
		info: true
	  },
	  {
		name: ["John Doe", true],
		email: "john@xyz.com",
		domainName: "www.xyz.com",
		date: "2023-07-12",
		info: true
	  },
	  {
		name: ["John Doe", true],
		email: "john@xyz.com",
		domainName: "www.xyz.com",
		date: "2023-07-12",
		info: true
	  },
	// Add more sample data objects as needed
  ];

export default function SendEmail() {
  return (
    <Box pt={{ base: "180px", md: "80px", xl: "80px" }}>
      <SendEmailTable  />
	  </Box>
  );
}

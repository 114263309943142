import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './assets/css/App.css';
import { HashRouter, Route, Switch, Redirect, useHistory, useLocation } from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import RTLLayout from './layouts/rtl';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './theme/theme';
import { ApiProvider } from 'contexts/apiContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AuthProvider, useAuthContext } from 'contexts/authContext';

const AppRouter: React.FC = () => {
	const { isLoggedIn } = useAuthContext();
	const history = useHistory();
	const location = useLocation();

	useEffect(() => {
		const currentPath = location.pathname;
		const lastVisitedPath = localStorage.getItem('lastVisitedPath') || '/admin';

		if (isLoggedIn) {
			if (currentPath === '/auth/sign-in') {
				history.replace(lastVisitedPath);
			} else {
				if (currentPath !== lastVisitedPath) {
					localStorage.setItem('lastVisitedPath', currentPath);
				}
			}
		} else {
			if (currentPath !== '/auth/sign-in') {
				localStorage.setItem('lastVisitedPath', currentPath);
				history.replace('/auth/sign-in');
			}
		}
	}, [isLoggedIn, history, location.pathname]);

	return (
		<Switch>
			{/* {isLoggedIn ? (
				<> */}
					<Route path={`/admin`} component={AdminLayout} />
					<Route path={`/rtl`} component={RTLLayout} />
					<Redirect exact from='/' to='/admin' />
				{/* </>
			) : (
				<> */}
					<Route path={`/auth`} component={AuthLayout} />
					<Redirect exact from='/' to='/auth/sign-in' />
				{/* </>
			)} */}
		</Switch>
	);
};

ReactDOM.render(
	<ChakraProvider theme={theme}>
		<React.StrictMode>
			<HashRouter>
				<ApiProvider>
					<AuthProvider>
						<ToastContainer />
						<AppRouter />
					</AuthProvider>
				</ApiProvider>
			</HashRouter>
		</React.StrictMode>
	</ChakraProvider>,
	document.getElementById('root')
);

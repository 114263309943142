import React, { useEffect, useState } from 'react';
import {
    Flex,
    Box,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Button,
    Icon,
    Tooltip,
    Spinner,
    Input,
} from '@chakra-ui/react';
import { FaRegTrashAlt, FaRegEdit, FaPlus, FaMailBulk, FaCloudUploadAlt } from 'react-icons/fa';
import { createColumnHelper, flexRender, getCoreRowModel, getSortedRowModel, SortingState, useReactTable } from '@tanstack/react-table';
import Card from 'components/card/Card';
import { deleteContact, getAllContactData } from 'libs/apiFunctions';
import AddContacts from '../AddContacts/AddContacts';
import Pagination from './Pagination'; // Import your custom Pagination component
import DeleteConfirmationToast from 'components/toast/DeleteConfirmationToast';
import ImportContactModal from '../ImportContactModal/ImportContactModal';
import CreateGroups from '../CreateGroups/CreateGroups';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';

type RowObj = {
    firstName: string;
    lastName: string;
    email: string;
    phone: string; // Update this to string
    websiteDomain: string;
    linkdedInUrl: string;
    _id: string
};

const columnHelper = createColumnHelper<RowObj>();

export default function ContactTable(props: { tableData: any }) {
    const [allContactData, setAllContactData] = useState<RowObj[]>([]);
    const [sorting, setSorting] = useState<SortingState>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [loading, setLoading] = useState(true);
    const [searchText, setSearchText] = useState("");
    const [pageNumber, setPageNumber] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [type, setType] = useState("")
    const [editData, setEditData] = useState<RowObj | null>(null);
    const [isImportContactsOpen, setIsImportContactsOpen] = useState(false);
    const [isImportGroupsOpen, setIsImportGroupsOpen] = useState(false);

    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
    const bgButton = useColorModeValue('secondaryGray.300', 'whiteAlpha.100');
    const iconColor = useColorModeValue('brand.500', 'white');
    const bgHover = useColorModeValue({ bg: 'primary.100' }, { bg: 'whiteAlpha.50' });
    const bgFocus = useColorModeValue({ bg: 'secondaryGray.300' }, { bg: 'whiteAlpha.100' });

    const fetchAllContactData = async () => {
        setLoading(true);
        try {
            const response = await getAllContactData(searchText, pageNumber);
            setAllContactData(response?.contacts || []);
            setTotalPages(response?.totalPages || 1);
        } catch (error) {
            console.error('Error fetching contact data:', error);
        } finally {
            setLoading(false);
        }
    };
    useEffect(() => {
        fetchAllContactData();
    }, [searchText, pageNumber]);

    const transformData = (data: RowObj[]) => {
        return data.map((item: RowObj) => ({
            firstName: item.firstName || '',
            lastName: item.lastName || '',
            email: item.email || '',
            phone: item.phone || '',
            websiteDomain: item.websiteDomain || '',
            linkdedInUrl: item.linkdedInUrl || '',
            _id: item._id || '',
        }));
    };

    const defaultData = transformData(allContactData);

    const handleEdit = (row: RowObj) => {
        setIsOpen(true);
        setType("Edit Contact")
        setEditData(row)
    };

    const handleDelete = (row: RowObj) => {
        DeleteConfirmationToast({
            message: "Are you sure you want to delete this contact?",
            onConfirm: async () => {
                let response = await deleteContact(row._id);
                await fetchAllContactData();
            },
            // onCancel: () => console.log("Deletion cancelled")
        });
    };

    const handleSendMail = (row: RowObj) => {
        console.log('Send Mail:', row);
    };

    const columns = [
        columnHelper.accessor('firstName', {
            id: 'firstName',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color='gray.400'>
                    First Name
                </Text>
            ),
            cell: (info) => (
                <Text color={textColor} fontSize='sm' fontWeight='700'>
                    {info.getValue()}
                </Text>
            ),
        }),
        columnHelper.accessor('lastName', {
            id: 'lastName',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color='gray.400'>
                    Last Name
                </Text>
            ),
            cell: (info) => (
                <Text color={textColor} fontSize='sm' fontWeight='700'>
                    {info.getValue()}
                </Text>
            ),
        }),
        columnHelper.accessor('email', {
            id: 'email',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color='gray.400'>
                    Email
                </Text>
            ),
            cell: (info) => (
                <Text color={textColor} fontSize='sm' fontWeight='700'>
                    {info.getValue()}
                </Text>
            ),
        }),
        columnHelper.accessor('phone', {
            id: 'phone',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color='gray.400'>
                    Phone
                </Text>
            ),
            cell: (info) => (
                <Text color={textColor} fontSize='sm' fontWeight='700'>
                    {info.getValue() !== null ? info.getValue() : 'N/A'}
                </Text>
            ),
        }),
        columnHelper.accessor('websiteDomain', {
            id: 'websiteDomain',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color='gray.400'>
                    Website Domain
                </Text>
            ),
            cell: (info) => (
                <Text color={textColor} fontSize='sm' fontWeight='700'>
                    {info.getValue()}
                </Text>
            ),
        }),
        columnHelper.display({
            id: 'actions',
            header: () => (
                <Text
                    justifyContent='space-between'
                    align='center'
                    fontSize={{ sm: '10px', lg: '12px' }}
                    color='gray.400'>
                    Actions
                </Text>
            ),
            cell: ({ row }: { row: any }) => (
                <Flex>
                    {/* <Tooltip borderRadius={8} label="Send Mail" fontSize="sm">
                        <Button
                            alignItems='center'
                            justifyContent='center'
                            bg={bgButton}
                            _hover={bgHover}
                            _focus={bgFocus}
                            _active={bgFocus}
                            mx={2}
                            w='37px'
                            h='37px'
                            lineHeight='100%'
                            borderRadius='10px'
                            onClick={() => handleSendMail(row.original)}
                        >
                            <Icon as={FaMailBulk} color={iconColor} w='20px' h='20px' />
                        </Button>
                    </Tooltip> */}
                    <Tooltip label="Edit" borderRadius={8} fontSize="sm">
                        <Button
                            alignItems='center'
                            justifyContent='center'
                            bg={bgButton}
                            _hover={bgHover}
                            _focus={bgFocus}
                            _active={bgFocus}
                            w='37px'
                            h='37px'
                            lineHeight='100%'
                            mx={2}
                            ms={0}
                            borderRadius='10px'
                            onClick={() => handleEdit(row.original)}
                        >
                            <Icon as={FaRegEdit} color={iconColor} w='20px' h='20px' />
                        </Button>
                    </Tooltip>
                    <Tooltip label="Delete" borderRadius={8} fontSize="sm">
                        <Button
                            alignItems='center'
                            justifyContent='center'
                            bg={bgButton}
                            _hover={bgHover}
                            _focus={bgFocus}
                            _active={bgFocus}
                            w='37px'
                            h='37px'
                            lineHeight='100%'
                            ms={0}
                            borderRadius='10px'
                            onClick={() => handleDelete(row.original)}
                        >
                            <Icon as={FaRegTrashAlt} color={iconColor} w='20px' h='20px' />
                        </Button>
                    </Tooltip>
                </Flex>
            ),
        }),
    ];

    const table = useReactTable({
        data: defaultData,
        columns,
        state: {
            sorting,
        },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    return (
        <>
            <Card
                w='100%'
                px='0px'
                overflowX={{ sm: 'scroll', lg: 'hidden' }}>
                <Flex px='25px' justify='space-between' mb='20px' align='center'>
                    <Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
                        Contacts
                    </Text>
                    <Box display={'flex'} alignItems={'center'}>
                        <Tooltip label="Import Contacts" borderRadius={8} fontSize="sm">
                            <Button
                                alignItems='center'
                                justifyContent='center'
                                variant='brand'
                                mx={2}
                                color={'white'}
                                lineHeight='100%'
                                borderRadius='30px'
                                onClick={() => setIsImportContactsOpen(true)}

                            >
                                <Icon as={FaCloudUploadAlt} color={'white'} w='20px' h='20px' mx='2' /> Import
                            </Button>
                        </Tooltip>
                        <Tooltip label="Create Groups" borderRadius={8} fontSize="sm">
                            <Button
                                alignItems='center'
                                justifyContent='center'
                                variant='brand'
                                mx={2}
                                color={'white'}
                                lineHeight='100%'
                                borderRadius='30px'
                                onClick={() => setIsImportGroupsOpen(true)}
                            >
                                <Icon as={FaPlus} color={'white'} w='20px' h='20px' mx='2' /> Create Groups
                            </Button>
                        </Tooltip>
                        <Tooltip label="Add Contacts" borderRadius={8} fontSize="sm">
                            <Button
                                alignItems='center'
                                justifyContent='center'
                                variant='brand'
                                mx={2}
                                color={'white'}
                                lineHeight='100%'
                                borderRadius='30px'
                                onClick={() => {setIsOpen(true); setType("Add Contact")}}
                            >
                                <Icon as={FaPlus} color={'white'} w='20px' h='20px' mx='2' /> Add Contacts
                            </Button>
                        </Tooltip>
                        <Input
                            placeholder='Search...'
                            value={searchText}
                            onChange={(e) => {
                                setSearchText(e.target.value);
                                setPageNumber(1); // Reset page number on search
                            }}
                            borderRadius='30px'
                            w='200px'
                            mx='10px'
                        />
                    </Box>
                </Flex>
                {loading ? (
                    <Flex justify='center' align='center' height='200px'>
                        <Spinner size='lg' />
                        <Text ml='4' fontSize='lg' color={textColor}>Loading...</Text>
                    </Flex>
                ) : (
                    <Box
                        borderRadius='16px'
                        // border='1px solid'
                        borderColor={borderColor}
                        overflow='hidden'>
                        <Table variant='simple'>
                            <Thead>
                                {table.getHeaderGroups().map(headerGroup => (
                                    <Tr key={headerGroup.id}>
                                        {headerGroup.headers.map(header => (
                                            <Th
                                                key={header.id}
                                                px={{ sm: '10px', md: '20px' }}
                                                py='12px'
                                                color='gray.400'
                                                fontSize='sm'>
                                                {header.isPlaceholder
                                                    ? null
                                                    : flexRender(
                                                        header.column.columnDef.header,
                                                        header.getContext()
                                                    )}
                                            </Th>
                                        ))}
                                    </Tr>
                                ))}
                            </Thead>
                            <Tbody>
                                {table.getRowModel().rows.map(row => (
                                    <Tr key={row.id}>
                                        {row.getVisibleCells().map(cell => (
                                            <Td key={cell.id}>
                                                {flexRender(
                                                    cell.column.columnDef.cell,
                                                    cell.getContext()
                                                )}
                                            </Td>
                                        ))}
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </Box>
                )}
                <Pagination
                    currentPage={pageNumber}
                    totalPages={totalPages}
                    onPageChange={(page) => setPageNumber(page)}
                />
            </Card>
            <AddContacts fetchAllContactData={fetchAllContactData} type={type} editData={editData} isOpen={isOpen} onClose={() => setIsOpen(false)} />
            <CreateGroups  fetchAllContactData={fetchAllContactData} isOpen={isImportGroupsOpen} onClose={() => setIsImportGroupsOpen(false)} />
            <ImportContactModal  isOpen={isImportContactsOpen} onClose={() => setIsImportContactsOpen(false)} fetchAllContactData={fetchAllContactData} />

        </>
    );
}

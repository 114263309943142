// src/components/DeleteConfirmationToast.tsx
import React from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

type DeleteConfirmationToastProps = {
    onConfirm: () => Promise<void>;
    onCancel?: () => void;
    message: string;
    successMessage?: string;
    errorMessage?: string;
    autoClose?: number;
};

const DeleteConfirmationToast: React.FC<DeleteConfirmationToastProps> = ({
    onConfirm,
    onCancel,
    message,
    successMessage = "Deleted successfully",
    errorMessage = "Failed to delete",
    autoClose = 3000
}) => {
    const confirmDeletion = async () => {
        try {
            await onConfirm();
            toast.success(successMessage);
        } catch (error) {
            toast.error(errorMessage);
        }
    };

    // Use toast directly with JSX content and handle ID implicitly
    toast.dark(
        <div>
            <p>{message}</p>
            <div style={{ marginTop: '10px' }}>
                <button
                    onClick={confirmDeletion}
                    style={{ marginRight: '10px', padding: '5px 10px', cursor: 'pointer' }}
                >
                    Yes
                </button>
                <button
                    onClick={() => {
                        if (onCancel) {
                            onCancel();
                        }
                        toast.info("Deletion cancelled");
                    }}
                    style={{ padding: '5px 10px', cursor: 'pointer' }}
                >
                    No
                </button>
            </div>
        </div>,
        {
            position: "top-center",
            autoClose: true,
            closeOnClick: true,
            draggable: false
        }
    );

    return null;
};

export default DeleteConfirmationToast;

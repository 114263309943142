import React, { useEffect, useState } from "react";
import {
  Flex,
  Box,
  Text,
  Button,
  Icon,
  Tooltip,
  Image,
  useColorModeValue,
  Spinner,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import { FaPlus } from "react-icons/fa";
import AddCampaigns from "../AddCampaigns/AddCampaigns";
import Nodata from "../../../../../assets/img/dashboards/Nodata.jpg";
import CampaignCard from "../CampaignCard/CampaignCard"; // Import a new component for card view
import { getAllCampaigns } from "libs/apiFunctions";
import { useHistory } from "react-router-dom";
import { useAuthContext } from "contexts/authContext";

const CampaignsTable = (props: { tableData: any }) => {
  const { tableData } = props;
  const [campaigns, setCampaigns] = useState(tableData);
  const [isOpen, setIsOpen] = useState(false);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");

  const handleAddCampaign = (newCampaign: any) => {
    setCampaigns((prevCampaigns: any) => [...prevCampaigns, newCampaign]);
  };

  const [campaingsData, setCampaignsData] = useState([]);
  const history = useHistory()
  const { setLogin } = useAuthContext();
  const fetchCampain = async () => {
    let response = await getAllCampaigns();
    if (response === 401) {
      setLogin(false)
      sessionStorage.removeItem('accessToken')
      sessionStorage.removeItem('isLoggedIn')
      history.push('/auth/sign-in');
    }
    setCampaignsData(response?.campaigns);
  };

  useEffect(() => {
    fetchCampain();
  }, []);

  return (
    <>
      <Box px="25px" justifyContent="space-between" my="20px">
        <Box>
          <Tabs variant="brand">
            <TabList sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex' }}>
                <Tab
                  sx={{
                    boxShadow: "none",
                    "&:focus": {
                      boxShadow: "none",
                    },
                    "&.chakra-tabs__tab[aria-selected='true']": {
                      color: "white",
                      fontWeight: "bold",
                      border: "2px solid blue",
                      borderRadius: "20px",
                      borderBottomColor: "blue",
                      backgroundColor: "#422AFB",
                    },
                  }}
                >
                  Total Campaigns
                </Tab>
                <Tab
                  sx={{
                    boxShadow: "none",
                    "&:focus": {
                      boxShadow: "none",
                    },
                    "&.chakra-tabs__tab[aria-selected='true']": {
                      color: "white",
                      fontWeight: "bold",
                      border: "2px solid blue",
                      borderRadius: "20px",
                      borderBottomColor: "blue",
                      backgroundColor: "#422AFB",
                    },
                  }}
                >
                  Total Contacts
                </Tab>
                <Tab
                  sx={{
                    boxShadow: "none",
                    "&:focus": {
                      boxShadow: "none",
                    },
                    "&.chakra-tabs__tab[aria-selected='true']": {
                      color: "white",
                      fontWeight: "bold",
                      border: "2px solid blue",
                      borderRadius: "20px",
                      borderBottomColor: "blue",
                      backgroundColor: "#422AFB",
                    },
                  }}
                >
                  Total Email Sent
                </Tab>
              </Box>
              <Box display="flex" alignItems="center">
                <Tooltip label="Add Campaigns" borderRadius={8} fontSize="sm">
                  <Button
                    alignItems="center"
                    justifyContent="center"
                    variant="brand"
                    mx={2}
                    color="white"
                    lineHeight="100%"
                    borderRadius="30px"
                    onClick={() => setIsOpen(true)}
                  >
                    <Icon as={FaPlus} color="white" w="20px" h="20px" mx="2" />
                    Create Campaign
                  </Button>
                </Tooltip>
              </Box>
            </TabList>

            <TabPanels p="0px!important">
              <TabPanel p="0px!important">
                {campaingsData?.length === 0 ? (
                  <Flex justify="center" align="center" height="200px">
                    <Spinner size="lg" />
                    <Text ml="4" fontSize="lg" color={textColor}>
                      Loading...
                    </Text>
                  </Flex>
                ) : (
                  <Flex
                    wrap="wrap"
                    px="0px!important"
                    p="25px"
                    gap="20px"
                    justifyContent="flex-start"
                  >
                    {campaingsData?.map((campaign: any, index: number) => (
                      <CampaignCard key={index} campaign={campaign} />
                    ))}
                  </Flex>
                )}
              </TabPanel>
              <TabPanel>
                <p>two!</p>
              </TabPanel>
              <TabPanel>
                <p>three!</p>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
      </Box>

      <AddCampaigns
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        onAddCampaign={handleAddCampaign}
        fetchCampain={fetchCampain}
      />
    </>
  );
};

export default CampaignsTable;

import React, { useEffect, useState } from 'react';
import {
    Flex,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
    Button,
    Tooltip,
    Input,
    Spinner,
} from '@chakra-ui/react';
import { FaRegTrashAlt, FaRegEdit, FaPlus, FaMailBulk, FaCheck, FaTimes } from 'react-icons/fa';
import {
    createColumnHelper,
    flexRender,
    getCoreRowModel,
    getSortedRowModel,
    SortingState,
    useReactTable,
} from '@tanstack/react-table';

// Custom components
import Card from 'components/card/Card';
import { SearchBar } from 'components/navbar/searchBar/SearchBar';
import CreateSendEmail from '../CreateSendEmail/CreateSendEmail';
import { sendMailHistory } from 'libs/apiFunctions';
import Pagination from 'views/admin/contacts/components/ContactsTable/Pagination';

type RowObj = {
    contactFirstName: string;
    senderFirstName: string;
    contactEmail: string;
    mailSubject: string;
    date: string; // You might need to format or convert this field
    campaignName: string;
    isEmailOpen: string;
};


const columnHelper = createColumnHelper<RowObj>();

export default function SendEmailTable() {
    const [sorting, setSorting] = useState<SortingState>([]);
    const [isOpen, setIsOpen] = useState(false);
    const [data, setData] = useState<RowObj[]>([]);
    const textColor = useColorModeValue('secondaryGray.900', 'white');
    const borderColor = useColorModeValue('gray.200', 'whiteAlpha.100');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [totalPages, setTotalPages] = useState(1);


    const columns = [
        columnHelper.accessor('contactFirstName', {
            id: 'contactFirstName',
            header: () => (
                <Text fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
                    Contact Name
                </Text>
            ),
            cell: (info) => (
                <Text color={textColor} fontSize='sm' fontWeight='700'>
                    {info.getValue()}
                </Text>
            ),
        }),
        columnHelper.accessor('senderFirstName', {
            id: 'senderFirstName',
            header: () => (
                <Text fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
                    Sender Name
                </Text>
            ),
            cell: (info) => (
                <Text color={textColor} fontSize='sm' fontWeight='700'>
                    {info.getValue()}
                </Text>
            ),
        }),
        columnHelper.accessor('contactEmail', {
            id: 'contactEmail',
            header: () => (
                <Text fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
                    Email
                </Text>
            ),
            cell: (info) => (
                <Text color={textColor} fontSize='sm' fontWeight='700'>
                    {info.getValue()}
                </Text>
            ),
        }),
        columnHelper.accessor('mailSubject', {
            id: 'mailSubject',
            header: () => (
                <Text fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
                    Subject
                </Text>
            ),
            cell: (info) => (
                <Text color={textColor} fontSize='sm' fontWeight='700'>
                    {info.getValue()}
                </Text>
            ),
        }),
        // columnHelper.accessor('date', {
        //     id: 'date',
        //     header: () => (
        //         <Text fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
        //             Date
        //         </Text>
        //     ),
        //     cell: (info) => (
        //         <Text color={textColor} fontSize='sm' fontWeight='700'>
        //             {info.getValue()}
        //         </Text>
        //     ),
        // }),
        columnHelper.accessor('campaignName', {
            id: 'campaignName',
            header: () => (
                <Text fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
                    Campaign Name
                </Text>
            ),
            cell: (info) => (
                <Text color={textColor} fontSize='sm' fontWeight='700'>
                    {info.getValue()}
                </Text>
            ),
        }),
        columnHelper.accessor('isEmailOpen', {
            id: 'isEmailOpen',
            header: () => (
                <Text fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
                    Open
                </Text>
            ),
            cell: (info) => (
                <Flex align='center'>
                    {info.getValue() ? <FaCheck color='green' /> : <FaTimes color='red' />}
                    {/* <Text ml='2'>{info.getValue() ? 'Open' : 'Closed'}</Text> */}
                </Flex>
            ),
        }),


        // columnHelper.display({
        //     id: 'actions',
        //     header: () => (
        //         <Text fontSize={{ sm: '10px', lg: '12px' }} color='gray.400'>
        //             Actions
        //         </Text>
        //     ),
        //     cell: ({ row }: { row: any }) => (
        //         <Flex justifyContent=''>
        //             <Tooltip label='Send Email' fontSize='md'>
        //                 <Button variant='outline' onClick={() => handleSendMail(row.original)}>
        //                     <FaMailBulk />
        //                 </Button>
        //             </Tooltip>
        //             <Tooltip label='Edit' fontSize='md'>
        //                 <Button variant='outline' onClick={() => handleEdit(row.original)}>
        //                     <FaRegEdit />
        //                 </Button>
        //             </Tooltip>
        //             <Tooltip label='Delete' fontSize='md'>
        //                 <Button variant='outline' onClick={() => handleDelete(row.original)}>
        //                     <FaRegTrashAlt />
        //                 </Button>
        //             </Tooltip>
        //         </Flex>
        //     ),
        // }),
    ];

    const [page, setPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');

    const fetchEmailHistory = async () => {
        setLoading(true);
        setError(null);
        try {
            let response = await sendMailHistory(page, searchTerm);
            if (response?.sentMails) {
                const formattedData = response.sentMails.map((mail: any) => ({
                    contactFirstName: mail.contactFirstName,
                    senderFirstName: mail.senderFirstName,
                    contactEmail: mail.contactEmail,
                    mailSubject: mail.mailSubject,
                    campaignName: mail.CampaignDetails[0]?.campaignName || "N/A",
                    isEmailOpen: mail.isOpen,
                }));
                setData(formattedData);
                setTotalPages(response?.totalPages)
            } else {
                setData([]);
            }
        } catch (error) {
            setError('Failed to fetch data. Please try again later.');
        } finally {
            setLoading(false);
        }
    };


    const handlePageChange = (newPage: number) => {
        setPage(newPage);
    };


    useEffect(() => {
        fetchEmailHistory();
    }, [page, searchTerm]);


    const {
        getHeaderGroups,
        getRowModel,
        getState,
        getFooterGroups,
        getAllColumns,
    } = useReactTable({
        data,
        columns,
        state: { sorting },
        onSortingChange: setSorting,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    return (
        <>
            <Card overflowX={{ sm: 'scroll', lg: 'hidden' }}>
                <Flex align='center' justify='space-between' mb='20px'>
                    <Text color={textColor} fontSize='22px' fontWeight='700' lineHeight='100%'>
                        Send Email
                    </Text>
                    <Flex>
                        <Input
                            placeholder='Search...'
                            value={searchTerm}
                            onChange={(e) => {
                                setSearchTerm(e.target.value);
                                setPage(1); 
                            }}
                            borderRadius='30px'
                            w='200px'
                            mx='10px'
                        />
                    </Flex>
                </Flex>

                {loading ? (
                    <Flex justify='center' align='center' height='200px'>
                        <Spinner size='lg' />
                        <Text ml='4' fontSize='lg' color={textColor}>Loading...</Text>
                    </Flex>
                ) : error ? (
                    <Flex align='center' justify='center' height='200px'>
                        <Text fontSize='lg' color='red.500'>{error}</Text>
                    </Flex>
                ) : data.length === 0 ? (
                    <Flex align='center' justify='center' height='200px'>
                        <Text fontSize='lg'>No data found</Text>
                    </Flex>
                ) : (
                    <>
                        <Table variant='simple'>
                            <Thead>
                                {getHeaderGroups()?.map(headerGroup => (
                                    <Tr key={headerGroup?.id}>
                                        {headerGroup?.headers?.map(header => (
                                            <Th key={header?.id}>{flexRender(header?.column?.columnDef?.header, header?.getContext())}</Th>
                                        ))}
                                    </Tr>
                                ))}
                            </Thead>
                            <Tbody>
                                {getRowModel()?.rows?.map(row => (
                                    <Tr key={row?.id}>
                                        {row?.getVisibleCells()?.map(cell => (
                                            <Td key={cell?.id}>{flexRender(cell?.column?.columnDef?.cell, cell?.getContext())}</Td>
                                        ))}
                                    </Tr>
                                ))}
                            </Tbody>
                            <tfoot>
                                {getFooterGroups()?.map(footerGroup => (
                                    <Tr key={footerGroup?.id}>
                                        {footerGroup?.headers?.map(header => (
                                            <Th key={header?.id}>{flexRender(header?.column?.columnDef?.footer, header?.getContext())}</Th>
                                        ))}
                                    </Tr>
                                ))}
                            </tfoot>
                        </Table>

                        <Pagination
                            currentPage={page}
                            totalPages={totalPages}
                            onPageChange={(page) => setPage(page)}
                        />
                    </>
                )}
            </Card>
            <CreateSendEmail isOpen={isOpen} onClose={() => setIsOpen(false)} />
        </>
    );

}

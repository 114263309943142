import { getAllContactData, getAllGroup } from "libs/apiFunctions";
import React, { createContext, useContext, useEffect, useState } from "react";

// Create a context
const apiContext = createContext();

// Create a custom hook to access the context
export const useApiContext = () => {
  return useContext(apiContext);
};

// Create a provider component
export const ApiProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);

  return (
    <apiContext.Provider value={{userData, setUserData}}>
      {children}
    </apiContext.Provider>
  );
};

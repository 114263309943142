import { Box, Flex, Image, Stack } from '@chakra-ui/react';
import Brand from 'components/sidebar/components/Brand';
import Links from 'components/sidebar/components/Links';
import SidebarCard from 'components/sidebar/components/SidebarCard';

function SidebarContent(props: { routes: RoutesType[] }) {
	const { routes } = props;

	// Filter out the "Sign In" route from the routes array
	const filteredRoutes = routes.filter(route => route.name !== 'Sign In');

	return (
		<Flex direction='column' height='100%' pt='25px' borderRadius='30px'>
			<Image src='https://gvmtechnologies.com/wp-content/uploads/2024/02/gvm-technolologies-llp-logo.png'  width='100%' maxWidth={'50%'} display={'flex'} justifyContent={'center'}  m={'auto'} marginTop={'0px'} marginBottom={'20px'} height='auto'  alt='brandLogo' />
			<Stack direction='column' mt='8px' mb='auto'>
				<Box ps='20px' pe={{ lg: '16px', '2xl': '16px' }}>
					<Links routes={filteredRoutes} />
				</Box>
			</Stack>

			<Box ps='20px' pe={{ lg: '16px', '2xl': '20px' }} mt='60px' mb='40px' borderRadius='30px'>
				<SidebarCard />
			</Box>
		</Flex>
	);
}

export default SidebarContent;
